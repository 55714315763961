import React from "react"
import { graphql } from "gatsby"
import { localize } from "../lib/helpers"

import Header from "../components/header"
import Seo from "../components/seo"
import BoldParagraph from "../components/boldParagraph"
import Cta from "../components/cta"

import Arrow from "../assets/svg/cta_arrow.svg"

const SuccessPage = ({ data, pageContext}) => {
  const
    { _rawTitle, _rawInstruction, text, _rawInstruction2, _rawCta, _rawCtaRight } = localize(data.sanitySuccess, [pageContext.language]);

  return (
    <div className="success-container">
      <Header lang={pageContext.language} />
      <Seo title={_rawTitle} lang={pageContext.language}/>
      <main>
        <h1 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="text">{_rawInstruction}</h1>
        <BoldParagraph containerClassName="success" content={text} elemClassName="TT28-elight"/>
        <p data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="text">{_rawInstruction2}</p>
        <div data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="cta-container">
          <a href={_rawCta.route} rel="noreferrer" target="_blank" className="cta">{_rawCta.title}<Arrow className="arrow"/></a>
          <Cta cta={_rawCtaRight}/>
        </div>
      </main>
    </div>
  )
}

export const query = graphql`
  query SuccessPage($language: String) {
    sanitySuccess {
      _rawTitle
      _rawInstruction
      text {
        translate(language: $language)
        isBold
      }
      _rawInstruction2
      _rawCta
      _rawCtaRight
    }
  }
`

export default SuccessPage
